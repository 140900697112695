<template>
  <div v-if="!route.path.startsWith('/customs-privacy')"
       class="bg-blue p-[6vw] md:p-[5.625vw] relative overflow-hidden text-indigo"
  >
    <div class="bg-white rounded-[5vw] md:rounded-[1.375vw] z-10 relative px-[6vw] md:p-[4vw]  md:pb-[2.5vw] shadow-m">
      <div class="flex justify-center flex-col items-center pt-[10vw] md:pt-[0vw] md:flex-row md:justify-between md:items-end">
        <NuxtLink href="/">
          <SvgTtfLogo class="w-[30vw] md:w-[9.374vw] md:h-[6.7vw] mb-[8vw] md:mb-0 text-red" />
        </NuxtLink>
        <div class="flex space-x-[4vw] md:space-x-[1vw]">
          <ButtonRegular v-for="item in main.slice(1)"
                         :key="item.id"
                         :href="item.url"
                         background-color="red"
                         color="white"
                         @click="trackEvent('footer-menu-link', { props: { link: item.url } })"
          >
            {{ item.title }}
          </ButtonRegular>
        </div>
      </div>

      <div class="w-full border-t border-indigo/10 my-[5vw] md:my-[2vw]" />

      <div>
        <div class="md:flex">
          <div class="flex flex-col space-y-[3vw] md:space-y-[1vw]">
            <FooterPrimary v-for="item in primary"
                           :key="item.id"
                           :href="item.url"
                           @click="trackEvent('footer-menu-link', { props: { link: item.url } })"
            >
              {{ item.title }}
            </FooterPrimary>
          </div>

          <div v-show="(complianceResultState.O7Compliance_AgeLimitPassed !== false)"
               class="mt-[5vw] md:mt-0 md:ml-[14vw] flex flex-col space-y-[2vw] md:space-y-[1vw]"
          >
            <FooterSecondary v-for="item in secondary"
                             :key="item.id"
                             :href="item.url"
                             @click="trackEvent('footer-menu-link', { props: { link: item.url } })"
            >
              {{ item.title }}
            </FooterSecondary>
          </div>
        </div>
        <div class="flex justify-between items-end mt-[5vw] md:mt-[4.5vw] mb-[4vw] md:mb-0">
          <div class="flex flex-wrap items-center space-x-[1.5vw] md:space-x-[0.5vw]">
            <FooterLegal v-for="(item, idx) in legal"
                         :key="item.id"
                         :href="item.url"
                         :class="{'font-bold': idx === 0}"
                         :separator="idx !== legal.length - 1"
            >
              {{ item.title }}
            </FooterLegal>
          </div>

          <div v-if="false"
               class="hidden md:block"
          >
            <LanguageSelector :filled="true" />
          </div>
        </div>

        <div class="w-full border-t border-indigo/10 my-[3vw] md:mt-[1.5vw] md:mb-[2vw]" />
        <FooterBottom />
      </div>
    </div>
    <SvgShapeGearSvg class="spin absolute h-[52vw] md:h-[32vw] w-[52vw] md:w-[52vw] -top-[18vw] -left-[17.5vw] -z-0 text-pink" />
    <SvgShapeGearSharpSvg class="spin-sharp absolute h-[83vw] md:h-[24vw] w-[83vw] md:w-[24vw] top-[15vw] md:-top-[6vw] -right-[39vw] md:-right-[1vw] text-turquoise" />
    <SvgShapeCrescent class="absolute text-toxic h-[80vw] md:h-[35vw] w-[40vw] md:w-[17.125vw] bottom-[-24vw] md:-bottom-[12vw] -left-[10vw] md:left-[1vw]"
                      :rotate="-10"
                      :animate="false"
    />
    <SvgShapeCloud class="absolute text-yellow h-[171vw] md:h-[38vw] w-[300vw] md:w-[69vw] -bottom-[100vw] md:-bottom-[24vw] left-[28vw] md:left-auto md:-right-[18vw] rotate-[-16deg] md:rotate-[-45deg]" />
  </div>

  <div v-else>
    <Container>
      <div class="md:px-[6vw] mt-[8vw] md:mt-0 md:p-[4vw] md:pb-[2.5vw]">
        <FooterBottom />
      </div>
    </Container>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';

import { complianceResultState } from '@outfit7/o7compliance-client';

import main from '../content/navigation/main.json';
import primary from '../content/navigation/primary.json';
import secondary from '../content/navigation/secondary.json';
import legal from '../content/navigation/legal.json';
import { useNuxtApp } from '#imports';

const { $event } = useNuxtApp();
const trackEvent = $event;

const route = useRoute();
</script>

<style scoped>
.spin-sharp {
  animation: spin 36s linear infinite;
}

.spin {
  animation: spin-reverse 60s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>